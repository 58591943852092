import BaseApi from "@/common/api/BaseApi";
import CustomerAddressCollection from "@/common/collections/CustomerAddressCollection";
import CustomerAddressModel from "@/common/models/CustomerAddressModel";

import CustomerRankTypeCollection from "@/common/collections/CustomerRankTypeCollection";
import CustomerEventModel from "@/common/models/CustomerEventModel";
import CustomerModel from "@/common/models/CustomerModel";
import {
	CartRegisterJson,
	CheckCustomerJson,
	ConfirmPasswordAddEditJson,
	ConfirmPasswordCustomerRes,
	CustomerAddressAddEditJson,
	CustomerAddressCollectionJson,
	CustomerAddressJson,
	CustomerEventFilters,
	CustomerEventJson,
	CustomerFiler,
	CustomerJson,
	CustomerJsonAddEdit,
	CustomerQuickRegisterJson,
	CustomerRankTypeCollectionJson,
	CustomerRankTypeFilters,
	CustomerUsernameAddEditJson,
	PasswordAddEditJson,
	SenderCustomerAddEditJson,
	UpdateQuickCustomer,
	VerifyCustomerAddEditJson,
} from "@/types/Customer.type";
import Helper from "@/utils/helper";
import { callApi, callApiV2 } from "@/lib/config/Api";
import { ForgotPasswordJson } from "@/types/Auth.type";
import { ResponseApiClient } from "@/lib/config/axiosClient";
import { ErrorServer } from "@/common/api/Error";
import { BaseCollectionJson } from "@/common/types/BaseCollection.type";
export async function createCustomerAddress(
	data: CustomerAddressAddEditJson
): Promise<CustomerAddressModel> {
	let item = new CustomerAddressModel(CustomerAddressModel.getDefaultData());
	let dataProps: any = { ...data };
	delete dataProps.id;
	let postData = {
		...dataProps,
	};
	const response = await callApi<CustomerAddressJson>(
		`/customers/address/customer`,
		"post",
		{ data: postData }
	);
	if (response.data) {
		item = new CustomerAddressModel(response.data);
	} else {
		item.withError(BaseApi.handleErrorCore(response.error));
	}

	return item;
}

export async function updateCustomerAddress(
	data: CustomerAddressAddEditJson
): Promise<CustomerAddressModel> {
	let item = new CustomerAddressModel(CustomerAddressModel.getDefaultData());
	let dataProps: any = { ...data };
	delete dataProps.id;
	let postData = {
		...dataProps,
	};
	const response = await callApi<CustomerAddressJson>(
		`/customers/address/${data.id}/customer`,
		"put",
		{ data: postData }
	);
	if (response.data) {
		item = new CustomerAddressModel(response.data);
	} else {
		item.withError(BaseApi.handleErrorCore(response.error));
	}

	return item;
}


export async function createCustomerAddressBySeller(
	data: Required<CustomerAddressAddEditJson>
): Promise<CustomerAddressModel> {
	let item = new CustomerAddressModel(CustomerAddressModel.getDefaultData());
	let dataProps: any = { ...data };
	delete dataProps.id;
	let postData = {
		...dataProps,
	};
	const response = await callApi<CustomerAddressJson>(
		`/customers/address`,
		"post",
		{ data: postData }
	);
	if (response.data) {
		item = new CustomerAddressModel(response.data);
	} else {
		item.withError(BaseApi.handleErrorCore(response.error));
	}

	return item;
}

export async function updateCustomerAddressBySeller(
	data: Required<CustomerAddressAddEditJson>
): Promise<CustomerAddressModel> {
	let item = new CustomerAddressModel(CustomerAddressModel.getDefaultData());
	let dataProps: any = { ...data };
	delete dataProps.id;
	let postData = {
		...dataProps,
	};
	const response = await callApi<CustomerAddressJson>(
		`/customers/address/${data.id}`,
		"put",
		{ data: postData }
	);
	if (response.data) {
		item = new CustomerAddressModel(response.data);
	} else {
		item.withError(BaseApi.handleErrorCore(response.error));
	}

	return item;
}

export async function deleteCustomerAddress(
	id: number
): Promise<ResponseApiClient<boolean>> {
	const response = await callApi<any>(`/customers/address/${id}`, "delete");

	if (response.data) {
		return {
			data: response.data,
		};
	} else {
		return {
			errors: BaseApi.handleErrorCore(response.error).errors,
		};
	}
}

export async function getDetailCustomerAddress(
	id: number
): Promise<CustomerAddressModel> {
	let item = new CustomerAddressModel(CustomerAddressModel.getDefaultData());

	const response = await callApi<CustomerAddressJson>(
		`/customers/address/${id}`,
		"get"
	);
	if (response.data) {
		item = new CustomerAddressModel(response.data);
	} else {
		item.withError(BaseApi.handleErrorCore(response.error));
	}

	return item;
}

export async function getListCustomerAddress(): Promise<CustomerAddressCollection> {
	let collection = new CustomerAddressCollection();

	const response = await callApi<CustomerAddressCollectionJson>(
		`/customers/address/customer`,
		"get"
	);
	if (response.data) {
		collection.fromJson(response.data);
	} else {
		collection.withError(BaseApi.handleErrorCore(response.error));
	}

	return collection;
}

export async function quickRegister(
	data: CustomerQuickRegisterJson
): Promise<CustomerModel> {
	let item = new CustomerModel(CustomerModel.getDefaultData());

	const response = await callApi<CustomerJson>(
		`/customers/public/register`,
		"post",
		{ data: data }
	);
	if (response.data) {
		item = new CustomerModel(response.data);
	} else {
		item.withError(BaseApi.handleErrorCore(response.error));
	}
	return item;
}
//membership
export async function getCustomerRankType(
	filters: CustomerRankTypeFilters
): Promise<CustomerRankTypeCollection> {
	const apiParams = Helper.convertFilterToParams(filters);
	let collection = new CustomerRankTypeCollection();

	const response = await callApi<CustomerRankTypeCollectionJson>(
		`/customers/ranktype?${apiParams}`,
		"get"
	);
	if (response.data) {
		collection.fromJson(response.data);
	} else {
		collection.withError(BaseApi.handleErrorCore(response.error));
	}

	return collection;
}

export async function getCustomerEvent(
	filters: CustomerEventFilters
): Promise<CustomerEventModel> {
	const apiParams = Helper.convertFilterToParams(filters);

	let item = new CustomerEventModel(CustomerEventModel.getDefaultData());

	const response = await callApi<CustomerEventJson>(
		`/events/checkeventrank?${apiParams}`,
		"get"
	);
	if (response.data) {
		item = new CustomerEventModel(response.data);
	} else {
		item.withError(BaseApi.handleErrorCore(response.error));
	}

	return item;
}

//update info

export async function senderCustomer(
	data: SenderCustomerAddEditJson,
	handleFailed?: (err: ErrorServer | null) => void
): Promise<ResponseApiClient<ForgotPasswordJson>> {
	const response = await callApi<ForgotPasswordJson>(
		process.env.REACT_APP_BASE_URL + "/customers/sender",
		"post",
		{
			data: data,
		}
	);
	if (!response.data) {
		handleFailed && handleFailed(response.error);
	}

	return {
		data: response.data,
	};
}

export async function updateCustomer(
	id: number,
	data: CustomerJsonAddEdit
): Promise<CustomerModel> {
	let item = new CustomerModel(CustomerModel.getDefaultData());

	const response = await callApi<CustomerJson>(`/customers/${id}`, "put", {
		data: data,
	});
	if (response.data) {
		item = new CustomerModel(response.data);
	} else {
		item.withError(BaseApi.handleErrorCore(response.error));
	}

	return item;
}
export async function verifyCustomer(
	data: VerifyCustomerAddEditJson,
	handleFailed?: (err: ErrorServer | null) => void
): Promise<ResponseApiClient<boolean>> {
	const response = await callApi<boolean>("/customers/verify", "post", {
		data: data,
	});
	if (!response.data) {
		handleFailed && handleFailed(response.error);
	}
	return {
		data: response.data,
	};
}
export async function getCustomer(id?: number) {
	const response = await callApiV2<CustomerJson>(`/customers/customer`, "get");

	return response;
}
export async function getCustomerBySeller(id: number) {
	const response = await callApiV2<CustomerJson>(`/customers/${id}`, "get");

	return response;
}
export async function checkSenderCustomer(
	data: SenderCustomerAddEditJson,
	handleFailed?: (err: ErrorServer | null) => void
): Promise<ResponseApiClient<boolean>> {
	const response = await callApi<boolean>("/customers/checksender", "post", {
		data: data,
	});

	if (!response.data) {
		handleFailed && handleFailed(response.error);
	}
	return {
		data: response.data,
	};
}

export async function changePasswordCustomer(
	body: PasswordAddEditJson
): Promise<ResponseApiClient<boolean>> {
	const response = await callApi<boolean>(
		`/users/customer/changepassword`,
		"put",
		{ data: body }
	);
	if (response.data) {
		return {
			data: response.data,
		};
	} else {
		return BaseApi.handleErrorCore(response.error);
	}
}

export async function confirmPasswordCustomer(
	body: ConfirmPasswordAddEditJson
): Promise<ResponseApiClient<ConfirmPasswordCustomerRes>> {
	const response = await callApi<ConfirmPasswordCustomerRes>(
		`/users/customer/checkpassword`,
		"post",
		{ data: body }
	);
	if (response.data) {
		return {
			data: response.data,
		};
	} else {
		return BaseApi.handleErrorCore(response.error);
	}
}

export async function changeUsernameCustomer(
	body: CustomerUsernameAddEditJson
): Promise<ResponseApiClient<boolean>> {
	const response = await callApi<boolean>(`/customers/sender`, "post", {
		data: body,
	});
	if (response.data) {
		return {
			data: response.data,
		};
	} else {
		return BaseApi.handleErrorCore(response.error);
	}
}

export async function updateQuickInfor(data: UpdateQuickCustomer) {
	const convertData = Helper.convertParams(data);
	const res = await callApiV2<CustomerJson>(`/customers/public/token`, "put", {
		data: convertData,
	});

	return res;
}

export async function checkStatusCustomer(id: string | number) {
	const res = await callApiV2<CheckCustomerJson>(
		`/customers/public/status/${id}`,
		"get"
	);
	return res;
}


export async function getCustomers(filter: CustomerFiler) {
	const res = await callApiV2<BaseCollectionJson<CustomerJson>>(
		`/customers`,
		"get",
		{
			params: filter
		}
	);
	return res;
}

export async function getListCustomerAddressByEmmployee(data: { customer_id: number }): Promise<CustomerAddressCollection> {
	let collection = new CustomerAddressCollection();

	const response = await callApi<CustomerAddressCollectionJson>(
		`/customers/address?customer_id=${data.customer_id}&sort_by=is_default`,
		"get"
	);
	if (response.data) {
		collection.fromJson(response.data);
	} else {
		collection.withError(BaseApi.handleErrorCore(response.error));
	}

	return collection;
}

export async function quickRegisterForCustomer(data: Pick<CustomerQuickRegisterJson,"age_range" | "gender" | "type">) {
	const response = await callApiV2<CartRegisterJson>(
		`/customers/user/register`,
		"post",
		{
			data: data,
		}
	);

	return response;
}

export async function registerForCustomer(data: CustomerQuickRegisterJson) {
	const response = await callApiV2<CartRegisterJson>(
		`/customers/user/register`,
		"post",
		{
			data: data,
		}
	);

	return response;
}